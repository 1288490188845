.channel-page, .video-snippets-page {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.page-header {
  margin-bottom: 20px;
}

.snippet-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 768px) {
  .channel-page, .video-snippets-page {
    padding: 10px;
  }
}
