.home-page {
  display: grid;
  grid-template-columns: 250px 1fr 300px;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.filter-panel, .featured-channels {
  position: sticky;
  top: 100px;
}

@media (max-width: 1024px) {
  .home-page {
    grid-template-columns: 1fr;
  }

  .filter-panel, .featured-channels {
    display: none;
  }
}

@media (max-width: 768px) {
  .home-page {
    padding: 10px;
  }
}
