.snippet-page {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.snippet-card {
  width: 100%;
  margin-bottom: 20px;
}

.snippet-image {
  max-height: 200px;
  object-fit: contain;
  width: 100%;
}

.snippet-actions {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.comments-section {
  margin-top: 40px;
}

@media (max-width: 768px) {
  .snippet-page {
    padding: 10px 0;
    max-width: 100%;
  }

  .snippet-image {
    max-height: 150px;
  }
}
