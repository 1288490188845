.snippet-card {
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.snippet-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

.snippet-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.snippet-thumbnail {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.snippet-content {
  margin-bottom: 10px;
}

.snippet-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 768px) {
  .snippet-card {
    padding: 15px;
  }

  .snippet-thumbnail {
    max-width: 80px;
    max-height: 80px;
  }
}
