.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 10px 0;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.navbar-logo {
  width: 180px;
  height: 48px;
}

.navbar-actions {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .navbar-container {
    justify-content: space-between;
    padding: 0 10px;
  }

  .navbar-logo {
    width: 120px;
    height: 32px;
  }

  .navbar-actions > *:not(:last-child) {
    display: none;
  }

  .navbar-actions > *:last-child {
    margin-left: auto;
  }
}
